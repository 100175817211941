import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadExcelComponent = _resolveComponent("UploadExcelComponent")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UploadExcelComponent, {
      "on-success": _ctx.handleSuccess,
      "before-upload": _ctx.beforeUpload
    }, null, 8, ["on-success", "before-upload"]),
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      "highlight-current-row": "",
      style: {"width":"100%","margin-top":"20px"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (item) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: item,
            prop: item,
            label: item
          }, null, 8, ["prop", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}